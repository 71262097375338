
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";
import NewBoxItemInfoModal from "@/components/modals/forms/NewBoxItemInfoModal.vue";
import UserModal from "@/components/modals/forms/UserModal.vue";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    NewBoxItemInfoModal,
    UserModal,
  },
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("赠送记录新增", ["赠送记录管理;gift_records"])
      } else {
        setCurrentPageBreadcrumbs("赠送记录编辑", ["赠送记录管理;gift_records", route.params.id.toString()])
      }
    });

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const gift_record = ref({
      name: "",
      gift_type: "",
      balance: 0
    })

    const user = ref({} as any)

    const product = ref({} as any)

    const onCancel = () => {
      router.push({ name: "gift_records" });
    };

    const isSubmit = ref(false)

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (isSubmit.value) {
        return
      }

      if (!formEl) return;

      formEl.validate((valid) => {
        console.log('validate')
        console.log(valid)
        console.log(user.value && user.value.id)
        console.log(gift_record.value.gift_type == 'product' && product.value && product.value.id)
        // console.log(gift_record.value.gift_type == 'balance' && gift_record.value.balance >= 0)
        console.log(gift_record.value.gift_type == 'point' && gift_record.value.balance >= 0)
        // console.log(((user.value && user.value.id) && ((gift_record.value.gift_type == 'product' && product.value && product.value.id) || (gift_record.value.gift_type == 'balance' && gift_record.value.balance >= 0))))
        console.log(((user.value && user.value.id) && ((gift_record.value.gift_type == 'product' && product.value && product.value.id) || (gift_record.value.gift_type == 'point' && gift_record.value.balance >= 0))))

        if (valid) {
          isSubmit.value = true

          // if ((user.value && user.value.id) && ((gift_record.value.gift_type == 'product' && product.value && product.value.id) || (gift_record.value.gift_type == 'balance' && gift_record.value.balance >= 0))) {
          if ((user.value && user.value.id) && ((gift_record.value.gift_type == 'product' && product.value && product.value.id) || (gift_record.value.gift_type == 'point' && gift_record.value.balance >= 0))) {
            store
            .dispatch(
              Actions.CREATE_GIFT_RECORD,
              {
                user_id: user.value.id,
                product_id: product.value.id,
                gift_type: gift_record.value.gift_type,
                balance: gift_record.value.balance
              }
            )
            .then(() => {
              router.push({ name: "gift_records" });
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
              isSubmit.value = false
            });
          }
        } else {
          return false;
        }
      });
    };

    const add_box_item_infos = (products) => {
      products.forEach((i) => {
        product.value = i;
      });
    };

    const add_users = (users) => {
      users.forEach((i) => {
        user.value = i;
      });
    };

    const gift_types = ref([] as any)
    gift_record.value.gift_type = 'product'
    gift_types.value.push({key: '商品', value: 'product'})
    // gift_types.value.push({key: '探玩币', value: 'balance'})
    gift_types.value.push({key: '探玩石', value: 'point'})

    return {
      onSubmit,
      gift_record,
      rules,
      onCancel,
      formRef,
      add_box_item_infos,
      product,
      user,
      add_users,
      gift_types
    };
  },
});
